/* You can add global styles to this file, and also import other style files */
@import "assets/scss/app.scss";

body {
  font-family: 'Poppins', sans-serif;
}
.btn-xs {
    font-size: 14px;
    /* padding: 4px; */
    padding: 2px 5px 2px 5px;
}

.no-scrollbar {
    overflow-x: hidden;
  }

  .form-container-filter{
    border: 1px solid #dddada;
    padding: 20px;
    margin: 5px;
  }
  
  .form-title-filter {
    font-size: 20px;
    margin-bottom: 10px;
  }
  

  .card-border{
    border: 1px solid #dddada;
    padding: 10px;
  }
  
  .card-border-tab{
    border: 1px solid #dddada;
    padding: 20px;
  }

  /* Estilo personalizado para aumentar el tamaño del checkbox */
.checkbox-large {
  transform: scale(1.5); /* Ajusta el valor según tus necesidades */
}


.custom-note {
  background-color: #f0f0f0; /* Color de fondo */
  border: 1px solid #ccc; /* Borde */
  padding: 10px; /* Espaciado interno */
  border-radius: 5px; /* Bordes redondeados */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra */
}

.custom-note p {
  color: #333; /* Color de texto */
  margin: 0; /* Elimina el margen del párrafo */
}

.cursor-pointer {
  cursor: pointer;
}

.ng-select.custom {
  width: 100%;
  min-height: 0px;
  border-radius: 0;
}

.ng-select.custom .ng-select-container  {            
  min-height: 0px;
  border-radius: 0;
  border-bottom: 1px solid;
}


.ng-select.custom ::ng-deep .ng-select-container  {            
  min-height: 0px;
  border-radius: 0;
}

ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: #dc3545;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
}

.ng-select.custom .ng-placeholder {
  display: block;
  color: #999; 
}


/* Estilo para hacer que la tabla sea responsiva */
.table-responsive {
  overflow-x: auto;
}

/* Estilo para ajustar el ancho de las columnas */
.table {
  width: 100%;
  max-width: 100%; /* Asegura que la tabla no exceda el ancho del contenedor */
}

/* Estilo para hacer que las columnas se ajusten automáticamente */
.nowrap th,
.nowrap td {
  white-space: nowrap;  /* Evita que el texto se divida en varias líneas */
}


